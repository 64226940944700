import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { SEO, Container, Block } from '../components/primitives';
import Swimmer from '../components/svg/Swimmer';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Section = styled.section.attrs({ className: 'relative text-gray-800' })`
  min-height: 70vh;
  svg {
    width: 320px;
  }
  h1 {
    margin: 4rem 0 0 0;
    font-weight: 600;
    text-align: center;
  }
`;

const Body = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NotFound = ({ location: { pathname } }) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Sorry. Page not found." description="Sorry. Page not found." />
      <Section>
        <Body>
          <Swimmer />
          <h1>Sorry. Page not found.</h1>
        </Body>
      </Section>
    </motion.article>
  );
};

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
